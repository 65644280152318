import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_portfolio_preview = _resolveComponent("portfolio-preview")

  return (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.$store.state.portfolioItems, (p) => {
    return (_openBlock(), _createBlock(_component_portfolio_preview, {
      key: p.name,
      data: p
    }, null, 8, ["data"]))
  }), 128))
}