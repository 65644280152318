
import { Actions } from "@/store";
import { defineComponent } from "@vue/runtime-core";
import { useStore } from "vuex";
import BlogPreview from "./components/BlogPreview.vue";
export default {
  components: { BlogPreview },
  created() {
    const store = useStore();
    store.dispatch(Actions.requestBlog);
  },
};
