
import ImageViewer from "@/components/ImageViewer.vue";
import { Actions } from "@/store";
import { defineComponent } from "@vue/runtime-core";

import marked from "marked";
import Prism from "prismjs";

export default defineComponent({
  components: {
    ImageViewer,
  },
  created(): void {
    this.$store.dispatch(
      Actions.requestSinglePortfolioPage,
      this.$route.params["slug"]
    );
  },
  computed: {
    content() {
      return marked(this.$store.state.singlePortfolioItem?.content || "");
    },
  },
  watch: {
    content() {
      setTimeout(() => {
        Prism.highlightAll();
      });
    },
  },
});
