
import { BlogPreviewItem } from "@/api/Api";
import { computed } from "vue";
export default {
  props: {
    data: Object,
  },
  setup(props: any) {
    return {
      publicationDate: computed(() => {
        let d = new Date(props.data.time)
        return d.toLocaleString()
      })
    }
  }
};
