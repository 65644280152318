
import { Actions } from "@/store";
import { defineComponent } from "@vue/runtime-core";
import PortfolioPreview from "./portfolio/components/PortfolioPreview.vue";
export default defineComponent({
  components: { PortfolioPreview },
  data() {
    return {};
  },
  created() {
    this.$store.dispatch(Actions.requestHomePage);
    document.body.style.setProperty('--scroll', (window.scrollY).toString())
    window.addEventListener('scroll', () => {
      document.body.style.setProperty('--scroll', (window.scrollY / (document.body.offsetHeight - window.innerHeight)).toString());
    }, false);
  },
});
