import { createRouter, createWebHistory } from 'vue-router'
import Home from './pages/Home.vue'
import Portfolio from './pages/Portfolio.vue'
import About from './pages/About.vue'
import PortfolioSingle from './pages/PortfolioSingle.vue'
import Blog from './pages/blog/Blog.vue'
import BlogSingle from './pages/blog/BlogSingle.vue'

const router = createRouter({
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active",
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            component: Home
        },
        {
            path: '/portfolio',
            component: Portfolio,
        },
        {
            path: '/portfolio/:slug',
            component: PortfolioSingle,
        },
        {
            path: '/blog',
            component: Blog,
        },
        {
            path: '/blog/:slug',
            component: BlogSingle,
        },
        {
            path: '/about',
            component: About,
        },
    ]
})
router.afterEach(() => {
    // FIXME? I have no idea why need setTimeout. Possible some DOM related things in firefox
    setTimeout(() => {
        window.scrollTo(0, 0)
    }, 0)

})
export default router