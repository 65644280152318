import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'prismjs/themes/prism.css'


import "bootstrap/js/dist/collapse"
// import "bootstrap"
// import 'bootstrap/scss/bootstrap.scss'
// import '@/style.scss'

createApp(App)
    .use(router)
    .use(store)
    .mount('#app')
