<template>
  <navigation />
  <div class="container page">
    <router-view></router-view>
  </div>
  <app-footer/>
</template>

<script>
import navigation from "@/components/Naviagation.vue";
import appFooter from '@/components/Footer.vue';

export default {
  components: {
    navigation,
    appFooter,
  },
  name: "App",
};
</script>

<style lang="scss">
body {
  background-color: $background-color !important;
}
.page {
  margin-top: 100px;
}
#app {
  color: #222;
  font-family: sans-serif;
 .page {
// 100px - header
// 1rem padding for foter x2 + 1rem font size of foter
    min-height: calc(100vh - 100px - 3rem) !important;
  }
}
</style>
