import FakeApi from "./FakeApi";

export interface PortfolioItem {
    slug: string,
    name: string,
    description: string,
    badges: Record<string, Array<string>>,
    previewImgSrc: string
}

export interface PortfolioFullPage extends PortfolioItem {
    content: string
    images: Array<string>
}

export interface AboutInfo {
    content: string
}

export interface ApiInterface {
    getPortfolioPreview(count: number, skip: number): Promise<Array<PortfolioItem>>
    getPortfolioFull(pageId: number): Promise<PortfolioFullPage>
    getPortfolioFullBySlug(slug: string): Promise<PortfolioFullPage>
    getBlog(): Promise<Array<BlogPreviewItem>>
    getBlogSingleBySlug(slug: string): Promise<BlogPage>
    getAboutInfo(): Promise<AboutInfo>
}


export interface BlogPreviewItem {
    slug: string
    name: string
    previewText: string
    time: number,
    tags: Array<string>
}

export interface BlogPage extends BlogPreviewItem {
    contentUrl: string
    content?: string
}

const a = new FakeApi();

export default function api(): ApiInterface {
    return a
}