import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_blog_preview = _resolveComponent("blog-preview")

  return (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.$store.state.blogItems, (b) => {
    return (_openBlock(), _createBlock(_component_blog_preview, {
      key: b.slug,
      data: b
    }, null, 8, ["data"]))
  }), 128))
}