
import { defineComponent } from "vue";

export default defineComponent({
  created() {
    document.body.addEventListener("click", () => {
      (this.$refs.navbar as HTMLElement).classList.remove("show");
    });
  },
});
