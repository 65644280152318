
import { defineComponent } from "vue";
import marked from "marked";
import Prism from "prismjs";
import { Actions } from "@/store";
export default defineComponent({
  created(): void {
    this.$store.dispatch(
      Actions.requestSingleBlogItem,
      this.$route.params["slug"]
    );
  },
  computed: {
    content(): string {
      var a = marked(this.$store.state.singleBlogItem?.content || "");
      return a;
    },
    publicationDate(): string {
      if (this.$store.state.singleBlogItem == null) {
        return ''
      }
      let d = new Date(this.$store.state.singleBlogItem!.time)
      return d.toLocaleString()
    }
  },
  watch: {
    content() {
      setTimeout(() => {
        Prism.highlightAll();
      });
    },
  },
});
