import { createStore } from 'vuex'
import api, { AboutInfo, BlogPreviewItem, BlogPage, PortfolioFullPage } from './api/Api'
import { PortfolioItem } from './api/Api'
import { ApiInterface } from './api/Api'


export interface GlobalStateInterface {
    portfolioItems: Array<PortfolioItem>,
    about: AboutInfo | null,
    singlePortfolioItem: PortfolioFullPage | null
    blogItems: Array<BlogPreviewItem>
    singleBlogItem: BlogPage | null
}

export enum Actions {
    requestHomePage = 'requestHomePage',
    requestPortfolioPage = 'requestPortfolioPage',
    requestAboutPage = 'requestAboutPage',
    requestSinglePortfolioPage = 'requestSinglePortfolioPage',
    requestBlog = 'requestBlog',
    requestSingleBlogItem = 'requestSingleBlogItem',
}

export enum Mutations {
    setPortfolioItems = 'setPortfolioItems',
    setAbout = 'setAbout',
    setSinglePortfolioItem = 'setSinglePortfolioItem',
    setBlogItems = 'setBlogItems',
    setSingleBlogItem = 'setSingleBlogItem',
}

export default createStore({
    state(): GlobalStateInterface {
        return {
            portfolioItems: [],
            about: null,
            singlePortfolioItem: null,
            blogItems: [],
            singleBlogItem: null,
        }
    },
    mutations: {
        [Mutations.setPortfolioItems](state: GlobalStateInterface, items: Array<PortfolioItem>) {
            state.portfolioItems = [...items];
        },
        [Mutations.setAbout](state, about: AboutInfo) {
            state.about = { ...about }
        },
        [Mutations.setSinglePortfolioItem](state, pageContent: PortfolioFullPage) {
            state.singlePortfolioItem = { ...pageContent }
        },
        [Mutations.setSingleBlogItem](state, item: BlogPage) {
            state.singleBlogItem = { ...item }
        },
        [Mutations.setBlogItems](state: GlobalStateInterface, items: Array<BlogPreviewItem>) {
            state.blogItems = [...items];
        },
    },
    actions: {
        async [Actions.requestHomePage]({ state, commit }) {
            console.log('requestHomePage');

            const items = await api().getPortfolioPreview(3, 0)
            commit(Mutations.setPortfolioItems, items)
        },

        async [Actions.requestPortfolioPage]({ state, commit }) {
            console.log('requestPortfolioPage');

            const items = await api().getPortfolioPreview(20, 0)

            const firstThree = items.splice(0, 3);
            // Add the first three elements to the end of the array
            items.push(...firstThree);

            commit(Mutations.setPortfolioItems, items)
        },

        async [Actions.requestAboutPage]({ state, commit }) {
            console.log('requestAboutPage');
            if (state.about != null) {
                return
            }

            const items = await api().getAboutInfo()
            commit(Mutations.setAbout, items)
        },

        async [Actions.requestSinglePortfolioPage]({ state, commit }, pageSlug: string) {
            commit(Mutations.setSinglePortfolioItem, null)
            const pageContent = await api().getPortfolioFullBySlug(pageSlug)
            commit(Mutations.setSinglePortfolioItem, pageContent)
        },

        async [Actions.requestBlog]({ state, commit }) {
            if (state.blogItems.length != 0) {
                return
            }
            const items = await api().getBlog()
            commit(Mutations.setBlogItems, items)
        },

        async [Actions.requestSingleBlogItem]({ state, commit }, blogSlug: string) {
            commit(Mutations.setSingleBlogItem, null)
            const pageContent = await api().getBlogSingleBySlug(blogSlug)
            commit(Mutations.setSingleBlogItem, pageContent)
        },
    }
})
