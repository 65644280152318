
import marked from "marked";
import { Actions } from "@/store";
import { defineComponent } from "@vue/runtime-core";
export default defineComponent({
  created() {
    this.$store.dispatch(Actions.requestAboutPage);
  },
  computed: {
    content() {
      return marked(this.$store.state.about?.content || "");
    },
  },
});
