
import { Actions } from "@/store";
import { defineComponent } from "@vue/runtime-core";
import PortfolioPreview from "./portfolio/components/PortfolioPreview.vue";
export default defineComponent({
  components: { PortfolioPreview },
  created() {
    this.$store.dispatch(Actions.requestPortfolioPage);
  },
});
