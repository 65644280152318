import { AboutInfo, ApiInterface, BlogPage, BlogPreviewItem, PortfolioFullPage, PortfolioItem } from "./Api";
import portfolioItems from '@/assets/portfolio/config'
import blogItems from '@/assets/blog/config'

export default class FakeApi implements ApiInterface {
    async getBlogSingleBySlug(slug: string): Promise<BlogPage> {
        const result = blogItems.find(item => item.slug == slug);
        if (result == null) {
            throw new Error("Not found");
        }
        const page = { ...result }
        /**
         * todo: do not load content if already loded. 
         * for example: if contect starts with '\'
         */
        page.content = await (await fetch(page.contentUrl)).text()

        return Promise.resolve(page)
    }
    async getBlog(): Promise<BlogPreviewItem[]> {
        return Promise.resolve(blogItems as unknown as Array<BlogPreviewItem>);
    }
    async getAboutInfo(): Promise<AboutInfo> {
        const a = {
            url: require('!!file-loader!@/assets/about.md')
        }
        const content = await (await fetch(a.url)).text()
        return Promise.resolve({
            content: content
        });
    }
    async getPortfolioFullBySlug(slug: string): Promise<PortfolioFullPage> {
        const result = portfolioItems.find((value) => value.slug == slug)
        if (result == null) {
            throw new Error("Not found");
        }
        const page = { ...result }
        /**
         * todo: do not load content if already loded. 
         * for example: if contect starts with '\'
         */
        page.content = await (await fetch(page.content)).text()

        return Promise.resolve(page)
    }
    getPortfolioPreview(count: number, skip: number): Promise<PortfolioItem[]> {
        return Promise.resolve([...portfolioItems].splice(skip).slice(0, count))
    }
    getPortfolioFull(pageId: number): Promise<PortfolioFullPage> {
        throw new Error("Method not implemented.");
    }

}