import { PortfolioFullPage } from '@/api/Api'

const items: PortfolioFullPage[] = [
    {
        name: "Simplecoin",
        description:
            "A crypto currency exchange platform",
        badges: {
            "Server side": ["PHP", "Yii2", "MySQL", "Kotlin"],
            "Client side": ["ReactJS", "NextJS"],
            "Admin side": ["jQuery", "Yii2 gui"]
        },
        previewImgSrc: require("@/assets/portfolio/simplecoin.jpg"),
        slug: "simplecoin",
        content: require('!!file-loader!@/assets/portfolio/simplecoin.md'),
        images: [
            require("@/assets/portfolio/simplecoin-big-1.jpg"),
            require("@/assets/portfolio/simplecoin-big-2.jpg"),
        ],
    },
    {
        name: "PRG Docs",
        description:
            "Internal database for manipulate personal details; Import/export documents, templates.",
        badges: {
            "Server side": ["PHP", "Laravel", "MySQL", "Java"],
            "Client side": ["VueJS", "Vuetify"]
        },
        previewImgSrc: require("@/assets/portfolio/prg.jpg"),
        slug: "prgdocs",
        content: require('!!file-loader!@/assets/portfolio/prg.md'),
        images: [
            require("@/assets/portfolio/prg-big-1.jpg"),
            require("@/assets/portfolio/prg-big-2.jpg"),
        ],
    },
    {
        name: "Digital pledge",
        description:
            "A tool for parents for having control over quality of time children spend online",
        badges: {
            "Client side": ["VueJS", "Vuetify", "iFrame"]
        },
        previewImgSrc: require("@/assets/portfolio/pledge.jpg"),
        slug: "digital-pledge",
        content: require('!!file-loader!@/assets/portfolio/pledge.md'),
        images: [
            require("@/assets/portfolio/pledge-big-1.jpg"),
            require("@/assets/portfolio/pledge-big-2.jpg"),
        ],
    },
    {
        name: "Be Major",
        description:
            "The digital performing arts academy.",
        badges: {
            "Server Side": ["PHP", "Laravel", "MySQL", "Ratchet php"],
            "Client Side": ["VueJS", "Vuetify", "Websocket"],
            "Api integration": ["Twilio"],
        },
        previewImgSrc: require("@/assets/portfolio/bemajor.jpg"),
        slug: "bemajor",
        content: require('!!file-loader!@/assets/portfolio/bemajor.md'),
        images: [
            require("@/assets/portfolio/bemajor-big-1.jpg"),
            require("@/assets/portfolio/bemajor-big-2.jpg"),
        ],
    },
    {
        name: "Irys",
        description: "Startup for promoting Foreign Direct Investment into Africa region.",
        badges: {
            "Server Side": ["Json server",],
            "Client Side": ["VueJS", "OpenStreetMap", "Maps.me"],
        },
        previewImgSrc: require("@/assets/portfolio/irys.jpg"),
        slug: "irys",
        content: require('!!file-loader!@/assets/portfolio/irys.md'),
        images: [
            require("@/assets/portfolio/irys-big-1.jpg"),
            require("@/assets/portfolio/irys-big-2.jpg"),
        ],
    },
    {
        name: "Camperdrive",
        description: "Aggregation platform for RV.",
        badges: {
            "Server Side": ["Laravel", "Postgres (POSTGIS)",],
            "Client Side": ["VueJS", "GoogleMaps API",],
        },
        previewImgSrc: require("@/assets/portfolio/camperdrive.jpg"),
        slug: "camperdrive",
        content: require('!!file-loader!@/assets/portfolio/camperdrive.md'),
        images: [
            require("@/assets/portfolio/camperdrive-big-1.jpg"),
            require("@/assets/portfolio/camperdrive-big-2.jpg"),
        ],
    },
]
export default items