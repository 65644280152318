
import { defineComponent, PropType } from "@vue/runtime-core";
import { PortfolioItem } from "@/api/Api";
export default defineComponent({
  props: {
    data: {
      // TODO: not validated on runtime. Need to check this
      type: Object as () => PortfolioItem,
      required: true,
    },
  },
  computed: {
    linkLocation(): string {
      return "/portfolio/" + this.data.slug;
    },
  },
});
